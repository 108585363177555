import { css } from "emotion";
import { colors } from ".";
import { Breakpoints } from "./grid";
import { spacing } from "./spacing";
export const sansSerif = "GT-Walsheim, sans serif";
export const serif = "Vollkorn, serif";

const importVollkorn = css`
  @import url("https://fonts.googleapis.com/css2?family=Vollkorn:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
`;

const importWalsheim = css`
@font-face {
  font-family: 'GT-Walsheim';
  src: url('../assets/fonts/GT-Walsheim-Regular.eot'); /* IE9 Compat Modes */
  src: url('../assets/fonts/GT-Walsheim-Regular?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../assets/fonts/GT-Walsheim-Regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('../assets/fonts/GT-Walsheim-Regular.woff') format('woff'), /* Pretty Modern Browsers */
       url('../assets/fonts/GT-Walsheim-Regular.ttf')  format('truetype'); /* Safari, Android, iOS */
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'GT-Walsheim';
  src: url('../assets/fonts/GT-Walsheim-Medium.eot'); /* IE9 Compat Modes */
  src: url('../assets/fonts/GT-Walsheim-Medium?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../assets/fonts/GT-Walsheim-Medium.woff2') format('woff2'), /* Super Modern Browsers */
       url('../assets/fonts/GT-Walsheim-Medium.woff') format('woff'), /* Pretty Modern Browsers */
       url('../assets/fonts/GT-Walsheim-Medium.ttf')  format('truetype'); /* Safari, Android, iOS */
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'GT-Walsheim';
  src: url('../assets/fonts/GT-Walsheim-Bold.eot'); /* IE9 Compat Modes */
  src: url('../assets/fonts/GT-Walsheim-Bold?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../assets/fonts/GT-Walsheim-Bold.woff2') format('woff2'), /* Super Modern Browsers */
       url('../assets/fonts/GT-Walsheim-Bold.woff') format('woff'), /* Pretty Modern Browsers */
       url('../assets/fonts/GT-Walsheim-Bold.ttf')  format('truetype'); /* Safari, Android, iOS */
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'GT-Walsheim';
  src: url('../assets/fonts/GT-Walsheim-Regular-Oblique.eot'); /* IE9 Compat Modes */
  src: url('../assets/fonts/GT-Walsheim-Regular-Oblique?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../assets/fonts/GT-Walsheim-Regular-Oblique.woff2') format('woff2'), /* Super Modern Browsers */
       url('../assets/fonts/GT-Walsheim-Regular-Oblique.woff') format('woff'), /* Pretty Modern Browsers */
       url('../assets/fonts/GT-Walsheim-Regular-Oblique.ttf')  format('truetype'); /* Safari, Android, iOS */
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'GT-Walsheim';
  src: url('../assets/fonts/GT-Walsheim-Medium-Oblique.eot'); /* IE9 Compat Modes */
  src: url('../assets/fonts/GT-Walsheim-Medium-Oblique?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../assets/fonts/GT-Walsheim-Medium-Oblique.woff2') format('woff2'), /* Super Modern Browsers */
       url('../assets/fonts/GT-Walsheim-Medium-Oblique.woff') format('woff'), /* Pretty Modern Browsers */
       url('../assets/fonts/GT-Walsheim-Medium-Oblique.ttf')  format('truetype'); /* Safari, Android, iOS */
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'GT-Walsheim';
  src: url('../assets/fonts/GT-Walsheim-Bold-Oblique.eot'); /* IE9 Compat Modes */
  src: url('../assets/fonts/GT-Walsheim-Bold-Oblique?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../assets/fonts/GT-Walsheim-Bold-Oblique.woff2') format('woff2'), /* Super Modern Browsers */
       url('../assets/fonts/GT-Walsheim-Bold-Oblique.woff') format('woff'), /* Pretty Modern Browsers */
       url('../assets/fonts/GT-Walsheim-Bold-Oblique.ttf')  format('truetype'); /* Safari, Android, iOS */
  font-weight: 00;
  font-style: italic;
}
`


const breakWord = css`
  overflow-wrap: break-word;
  word-break: break-word;
  hyphens: auto;`


const h1 = css`
  @extend ${importVollkorn};
  font-family: ${serif};
  font-size: 90px;
  line-height: 1em;
  margin-bottom: ${spacing.ml};
  font-weight: 600;
  color: ${colors.green.dark2};
  letter-spacing: -0.03em;
  
  @media screen and (${Breakpoints.tablet}) {
    font-size: 60px;
  }

  @media screen and (${Breakpoints.mobile}) {
    font-size: 60px;
    margin-bottom: ${spacing.m};
  }
`; 

const h2 = css`
  font-family: ${serif};
  font-size: 60px;
  line-height: 1em;
  font-weight: 600;
  color: ${colors.green.dark2};
  letter-spacing: -0.02em;
  @media screen and (${Breakpoints.mobile}) {
    font-size: 40px;
  }
`;
const h3 = css`
  font-family: ${serif};
  font-size: 40px;
  line-height: 1em;
  font-weight: 600;
  letter-spacing: -0.02em;
  color: ${colors.green.dark2};
  @media screen and (${Breakpoints.mobile}) {
    font-size: 32px;
  }
`;
const quote = css`
  font-family: ${serif};
  font-style: italic;
  font-size: 40px;
  line-height: 64px;
  font-weight: 400;
  @media screen and (${Breakpoints.mobile}) {
    font-size: 32px;
    line-height: 48px;
    letter-spacing: -0.02em;
  }
`;
const h4 = css`
  font-family: ${serif};
  font-size: 32px;
  line-height: 38px;
  font-weight: 600;
  letter-spacing: -0.02em;

  @media screen and (${Breakpoints.mobile}) {
    font-size: 28px;
    line-height: 36px;
  }
`;
const h5 = css`
  font-family: ${sansSerif};
  font-size: 28px;
  line-height: 40px;
  font-weight: 400;
  @media screen and (${Breakpoints.mobile}) {
    font-size: 22px;
    line-height: 28px;
  }
`;
const h6 = css`
  color: ${colors.green.green10};
  font-family: ${serif};
  font-size: 28px;
  line-height: 32px;
  font-weight: 400;
  letter-spacing: -0.02em;
  @media screen and (${Breakpoints.mobile}) {
    font-size: 22px;
    line-height: 24px;
  }
`;
const h6Bold = css`
  ${h6}
  font-weight: 600;
`;


const h7 = css`
font-family: ${serif};
  font-size: 22px;
  line-height: 24px;
  font-weight: 500;
  letter-spacing: -0.02em;
  @media screen and (${Breakpoints.mobile}) {
    font-size: 16px;
    line-height: 22px;
  }
`
const intro = css`
  font-family: ${sansSerif};
  font-size: 22px;
  line-height: 32px;
  font-weight: 400;
  color: ${colors.green.green11};
  @media screen and (${Breakpoints.mobile}) {
    font-size: 18px;
    line-height: 28px;
  }
`;

const subtitle = css`
  font-family: ${serif};
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  color: #00211B;
  @media screen and (${Breakpoints.mobile}) {
    font-size: 16px;
    line-height: 24px;
    text-transform: none;
  }
`;

const bodySansSerif = css`
  font-family: ${sansSerif};
  font-size: 20px;
  line-height: 32px;
  font-weight: 400;
  color: ${colors.green.green11};
  @media screen and (${Breakpoints.mobile}) {
    font-size: 16px;
    line-height: 24px;
  }
`;

const bodySerif = css`
  font-family: ${serif};
  font-size: 20px;
  line-height: 32px;
  font-weight: 400;
  @media screen and (${Breakpoints.mobile}) {
    font-size: 16px;
    line-height: 24px;
    text-transform: none;
  }
`;

const bodySerifBold = css`
  ${bodySerif}
  font-weight: 600;
`;

const body = css`
  font-family: ${sansSerif};
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
`; 

const subtitleSansSerif = css`
  font-family: ${sansSerif};
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
`;
const overline = css`
  font-family: ${sansSerif};
  text-transform: uppercase;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
`;

const caption = css`
  font-family: ${sansSerif};
  text-transform: uppercase;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
`;

const captionSerif = css`
  font-family: ${serif};
  text-transform: uppercase;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  margin-bottom: 5px;
`;

const captionItalic = css`
  font-family: ${serif};
  text-transform: uppercase;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  font-style: italic;
`;
export const typography = {
  serif,
  sansSerif,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  h6Bold,
  h7,
  intro,
  subtitle,
  body,
  quote,
  bodySerif,
  bodySansSerif,
  bodySerifBold,
  subtitleSansSerif,
  overline,
  caption,
  captionSerif,
  captionItalic,
  importWalsheim,
  breakWord
};
